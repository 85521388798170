const jhonData = {
  name: "Motoklub Osijek",
  role: "Na cesti od 1974.",
  ptext: "Motoklub Osijek je nezaobilazno svjetlo u moto zajednici koje traje već preko 45 godina.",
  socialLinks: [
    // Assuming the club has these social links; replace with actual URLs
    {
      name: "Facebook",
      url: "https://www.facebook.com/groups/83652485641/?locale=hr_HR",
      className: "social_facebook",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/mkosijek/?hl=en",
      className: "social_instagram",
    },
  ],
  aboutme: "O nama",
  aboutdetails: "Motoklub Osijek nezaobilazno je svjetlo koje se dogodilo prije 45 godina i traje u neprekinutom nizu. Trajanje je nezaobilazna komponenta vremena u kojemu živimo i umiremo, padamo i dižemo se te tako obogaćeni iskustvima djelujemo u cjelovitoj životnoj zajednici.",
  service: [
    // Custom services offered by the moto club (events, gatherings, etc.)
    {
      id: 1,
      iconName: "icon-map", // Adjust icons as needed
      serviceTitle: "Druženja i moto susreti",
      sDetails: "Druženja na motosusretima uvijek su nepisano pravilo primanja i vraćanja. MK Osijek organizira velik broj manifestacija humanitarnog karaktera.",
    },
    {
      id: 2,
      iconName: "icon-gift", // Adjust icons as needed
      serviceTitle: "Humanitarne akcije",
      sDetails: "Organiziramo velik broj manifestacija humanitarnog karaktera, što je iz socijalnog aspekta velik doprinos gradu.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Pronađi nas",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Adresa: Sv. Leopolda Bogdana Mandića 174, Sportski aerodrom Osijek, 31000 Osijek",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Telefon: +385 91 930 5107",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: info@mk-osijek.hr",
        },
      ],
    },
  ],
  devAbout: [
    // About club's mission and vision
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Strast, Srce & Duša",
      aHeadTwo: "Svaki veliki dizajn započinje s još boljom pričom",
      adiscription: "Od osnivanja, MK Osijek djeluje s ciljem povezivanja motociklista i promicanja motociklističke kulture u Hrvatskoj.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Organizacija",
      text: "Organiziramo motosusrete s jedinstvenim doživljajem",
      complateProject: "Preko 100 susreta",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "Zajednica",
      text: "Gradimo snažnu moto zajednicu s čistim i snažnim vezama",
      complateProject: "100-tinjak članova",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "Humanitarni rad",
      text: "Podižemo svijest i sredstva kroz humanitarne akcije",
      complateProject: "Nekoliko akcija godišnje",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    // Assuming hypothetical awards for Moto Club
    {
      id: 1,
      image: "logo1.png",
      ctile: "Moto susret godine 2020",
      date: "Travanj 2020",
      description: "Za najpopularniji moto susret prema glasovima zajednice",
      url: "http://www.pinterest.com",
      linktext: "Više informacija",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Humanitarna akcija 2021",
      date: "Ožujak 2021",
      description: "Za izuzetan doprinos u humanitarnom radu",
      url: "http://www.pinterest.com",
      linktext: "Više informacija",
    },
    {
      id: 3,
      image: "logo3.png",
      ctile: "Priznanje za zajednicu",
      date: "Ožujak 2021",
      description: "Za dugogodišnji doprinos moto zajednici u Osijeku",
      url: "http://www.pinterest.com",
      linktext: "Više informacija",
    },
  ],
  creativeBanner: [
    {
      id: 1,
      subtile: "Pozdrav, mi smo",
      name: "Motoklub Osijek",
      title: "Na cesti od 1974.",
    },
  ],
  creativeAbout: [
    {
      id: 1,
      smalltitle: "O nama",
      discription:
        "Pozdrav, mi smo Motoklub Osijek, strastveni zaljubljenici u motociklizam s dugogodišnjim iskustvom u organizaciji moto susreta i humanitarnog rada. Nastojimo kreirati jedinstvene doživljaje za naše članove i zajednicu, optimizirajući svaki događaj kako bismo osigurali nezaboravna iskustva.",
    },
  ],
  contacttitle: "Kontaktirajte nas",
};

export default jhonData;
