import React from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Gallery extends React.Component {
  state = {
    activeItem: '*',
    isOpen: false,
    photoIndex: 0,
  };

  componentDidMount() {
    var imgLoad = new ImagesLoaded('.grid');

    imgLoad.on('progress', () => {
      this.iso = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: "masonry",
      });
    });
  }

  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: "masonry",
      });
    }

    if (newFilter === '*') {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  onActive = (v) => (v === this.state.activeItem ? 'active' : '');

  openLightbox = (index) => {
    this.setState({ isOpen: true, photoIndex: index });
  };

  render() {
    const { images } = this.props;
    const { isOpen, photoIndex } = this.state;

    if (!images) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <div className="grid row">
          {images.map((image, index) => (
            <div
              key={index}
              className="col-md-3 col-sm-6 col-xs-12 grid-item"
              onClick={() => this.openLightbox(index)}
            >
              <div className="portfolio hover-style">
                <img
                  src={process.env.PUBLIC_URL + '/' + image}
                  alt=""
                  style={{ height: 300, objectFit: 'cover' }}
                />
                <div className="item-img-overlay">
                  <div className="overlay-info text-center">
                    {/* Removed the image name */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={process.env.PUBLIC_URL + '/' + images[photoIndex]}
            nextSrc={process.env.PUBLIC_URL + '/' + images[(photoIndex + 1) % images.length]}
            prevSrc={process.env.PUBLIC_URL + '/' + images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Gallery;
