import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
class Footer extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="footer-area">
        <div className="container">
          <div className="footer-content">
            <Link to="/" className="logo wow fadeInDown" data-wow-delay="0.4s">
              <img src={require("../image/logo2.png")} alt="" />
            </Link>
            <Fade direction="down" cascade triggerOnce>
              <ul className="list_style">
                {jhonData.socialLinks.map((item) => {
                  return (
                    <li key={item.name}>
                      <a href={item.url}>
                        <i className={item.className}></i>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Fade>
            <br></br>
            <p>© 2024 Moto Klub Osijek - All Rights Reserved</p>
          </div>
        </div>
      </section>
    );
  }
}
export default Footer;
