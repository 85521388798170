import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import CounterItem from "../Skill/CounterItem";
import { Fade } from "react-awesome-reveal";

class Skill extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: "easeOutCubic",
        update() {
          el.innerText = data.count.toLocaleString() + ' ' + '+';
        },
      });
    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll("[data-countup]");

    els.forEach(makeCountup);
  }
  render() {
    var { cClass } = this.props;
    return (
      <section className={`project_count_area ${cClass}`}>
        <div className="container">
          <Fade direction="up" cascade triggerOnce="true">
            <div className="row">
              <CounterItem
                CText="50"
                pdescription="Godina postojanja"
                icon="map"
              />
              <CounterItem
                CText="80"
                pdescription="Članova kluba"
                icon="happy"
              />
              <CounterItem
                CText="50"
                pdescription="Godina iskustva"
                icon="calendar"
              />
              <CounterItem CText="30" pdescription="Priznanja" icon="trophy" />
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}
export default Skill;
