import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA3xj5w5LHWDsbkvLksv6C5aeXtebzMkW4",
  authDomain: "mkosijek-d7199.firebaseapp.com",
  projectId: "mkosijek-d7199",
  storageBucket: "mkosijek-d7199.appspot.com",
  messagingSenderId: "895577820995",
  appId: "1:895577820995:web:bef42bb571cf0d7650a9f6",
  measurementId: "G-P2YDLP6CXH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const storageRef = ref(storage);
export const uploadBytesToStorage = uploadBytes;
export const getDownloadURLFromStorage = getDownloadURL;

export default app;

logEvent(analytics, 'page_view', {
  page_path: '/',
});
