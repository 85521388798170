import React, {Component} from 'react';
import Slider from 'react-slick';

 class TestimonialSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        arrows: false,
        autoplay: true,
        pauseOnHover:true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div>
         <Slider {...settings} className="testimonial_slider">
            <div className="item">
                <div className="author_img">
                    <img src={require('../../image/slide1.jpg')} alt=""/>
                </div>
                <h6>Slavimo Povijest</h6>
                <span>Od 1974. godine</span>
                <p>Motoklub Osijek je svjetionik strasti i zajednice za ljubitelje motocikala od 1974. godine. Dok slavimo više od četiri desetljeća neprekinute tradicije, naše putovanje stoji kao svjedočanstvo vječne veze vozača kroz generacije. Naša povijest nije samo mjera vremena, već kontinuirana baština uzdizanja nakon padova, življenja punim plućima i obogaćivanja moto zajednice s našim iskustvima.</p>
            </div>
            <div className="item">
                <div className="author_img">
                    <img src={require('../../image/slide2.jpg')} alt=""/>
                </div>
                <h6>Tko smo</h6>
                <span>Motoklub Osijek, najstariji klub na području bivše Jugoslavije</span>
                <p>Saznajte nešto više o našim ciljevima, dostignućima i viziji Motokluba Osijek. Osnovani s misijom i vizijom vodstva motokulture kroz zajednicu, kontinuirano radimo na promociji i podršci motociklizma.</p>
            </div>
            <div className="item">
                <div className="author_img">
                    <img src={require('../../image/slide4.jpg')} alt=""/>
                </div>
                <h6>Pridruži nam se</h6>
                <span>Postanite dio naše zajednice</span>
                <p>Što je potrebno da postanete dio Motokluba Osijek? Otkrijte kako se pridružiti našoj strastvenoj zajednici ljubitelja motocikala i sudjelovati u našim događanjima i aktivnostima.</p>
            </div>
        </Slider>
        </div>
      );
    }
  }
  export default TestimonialSlider;