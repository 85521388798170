import React, { Component } from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import { Link } from 'react-router-dom';

class ItemGrid extends Component {
  state = {
    activeItem: '*',
  };

  componentDidMount() {
    var imgLoad = new ImagesLoaded('.grid');

    imgLoad.on('progress', () => {
      this.iso = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: "masonry",
      });
    });
  }

  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: "masonry",
      });
    }

    if (newFilter === '*') {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  onActive = (v) => (v === this.state.activeItem ? 'active' : '');

  render() {
    const { galleries } = this.props;

    if (!galleries) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <div className="grid row">
          {Object.keys(galleries).map((galleryName, index) => (
            <div key={index} className="col-md-3 col-sm-6 col-xs-12 grid-item">
              <Link to={`/${galleryName}`}>
                <div className="portfolio hover-style">
                  <img src={`${process.env.PUBLIC_URL}/${galleries[galleryName][0]}`} alt="" style={{ height: 300, objectFit: 'cover' }} />
                  <div className="item-img-overlay">
                    <div className="overlay-info text-center">
                      <h6 className="sm-titl">{galleryName.replace(/_/g, ' ')}</h6>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ItemGrid;
