import React, {Component} from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from '../component/Banner/Sectiontitle';

class Clientslogo extends  Component{
    render(){
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1 ,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return(
            <section className="clients_logo_area">
                <div className="container">
                    <Sectiontitle Title="Prijateljski klubovi: Više od Druženja" TitleP="Kroz Godine Iskustva i Vožnje, Poštivanje Je Temelj
U svijetu prijateljskih klubova, svaka kilometraža je ispričana priča, a svako okupljanje je prilika za jačanje veza. Naša zajednica nije samo skup prijatelja - ona je obitelj na dvije kotača. Kroz godine vožnje, zajedničkih izazova i neprocjenjivih trenutaka, poštovanje je temelj na kojem gradimo našu strast i zajedništvo.

Ovo nije samo klub, ovo je naš životni stil. Spremni smo za svaku cestu koja nam se pruži, jer zajedno smo jači, brži i hrabriji. Naša ekipa je više od sudionika - mi smo moto entuzijasti koji svojim srcem voze i svojom dušom dišu duh slobode na dva kotača."/>
                    <Clientsliders {...settings} className="clients_slider">
                        <div className="item">
                            <a href=".#"><img src={require('../image/1.jpg')} alt="Moto Klub Tuzla" /></a>
                        </div>
                    </Clientsliders>
                </div>
            </section>
        );
    }
    
}
export default Clientslogo;