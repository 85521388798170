import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home4 from "./Home4";
import NotFound from "./404";
import Gallery from './component/Gallery'; // New dynamic gallery component

function App() {
  const [galleries, setGalleries] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/galleries.json`)
      .then(response => response.json())
      .then(data => {
        const processedGalleries = {};
        for (const [key, value] of Object.entries(data)) {
          let newKey = key.replace(/ /g, '_').toLowerCase();
          if (/^\d{4}$/.test(newKey) && parseInt(newKey) < 2000) {
            newKey = 'Klub';
          } else if (newKey.includes('mrazovi')) {
            newKey = 'moto_mrazovi';
          } else if (newKey.includes('zeko')) {
            newKey = 'moto_zeko';
          } else {
            newKey = 'ostali_dogadjaji';
          }
          if (!processedGalleries[newKey]) {
            processedGalleries[newKey] = [];
          }
          processedGalleries[newKey] = [...processedGalleries[newKey], ...value];
        }
        setGalleries(processedGalleries);
      });
  }, []);

  if (!galleries) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home4 galleries={galleries} />} />
          {Object.keys(galleries).map((galleryName) => (
            <Route
              key={galleryName}
              path={`/${galleryName}`}
              element={<Gallery images={galleries[galleryName]} />}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
