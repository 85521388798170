import React from "react";
import emailjs from '@emailjs/browser';
import { db } from '../firebaseConfig'; // Make sure to have this import to use the Firestore instance
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore functionalities
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS



class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: "",
        subject: "",
        phone: "",
        email: "",
      },
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Name is not empty" : "";
        break;
      case "subject":
        errors.subject = value.length < 5 ? "Subject must be 5 characters" : "";
        break;
      case "phone":
        errors.phone = value.length < 5 ? "phone is not empty" : "";
        break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }

        break;

      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  isValidForm = () => {
    const { errors, name, email, subject, phone, message } = this.state;
    const isValid = !Object.values(errors).some(error => error) &&
                    name && email && subject && phone && message;
    return isValid;
  }

  submitHandler = async (e) => {
    e.preventDefault();

    if (this.isValidForm()) {
      try {
        const newContact = {
          name: this.state.name,
          email: this.state.email,
          subject: this.state.subject,
          phone: this.state.phone,
          message: this.state.message,
        };

        await setDoc(doc(db, "contacts", `${Date.now()}`), newContact);

        toast.success("Poruka uspješno poslana!"); 
      } catch (error) {
        toast.error("Došlo je do greške.");
      }
    } else {
      toast.error("Popunite ispravno polja!");
    }
  };

  render() {
    const { errors } = this.state;
    const isFormValid = this.isValidForm();
    return (
      <form onSubmit={this.submitHandler} className="form_class">
        <div className="row">
          <div className="col-lg-6">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Vaše ime*"
              onChange={this.handleChange}
            />
            <p>{errors.name}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Vaša Email adresa*"
              onChange={this.handleChange}
            />
            <p>{errors.email}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              id="subject"
              name="subject"
              className="form-control"
              placeholder="Tema*"
              onChange={this.handleChange}
            />
            <p>{errors.subject}</p>
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Kontakt telefon*"
              onChange={this.handleChange}
            />
            <p>{errors.phone}</p>
          </div>
        </div>
        <textarea
          name="message"
          id="message"
          className="form-control"
          rows="6"
          placeholder="Vaša poruka ..."
          onChange={this.handleChange}
        ></textarea>
        <button type="submit" className="btn send_btn theme_btn" disabled={!isFormValid}>
          Pošalji poruku
        </button>
      </form>
    );
  }
}

export default Form;
